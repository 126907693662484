import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Conversation, Conversations } from "../../types";
import { useCallback } from "react";
import { getChatConversationsQueryKey } from "../../queryFunctions/getChatConversations";
import useGetDefaultRequestOptions from "./useGetDefaultRequestOptions";
import deleteChatConversation, { deleteChatConversationQueryKey } from "../../queryFunctions/deleteChatConversation";

const useDeleteChatConversation = (conversation: Conversation) => {
  const defaultRequestOptions = useGetDefaultRequestOptions()!;
  const queryClient = useQueryClient();

  const removeItemMutation = useMutation({
    mutationFn: deleteChatConversation(defaultRequestOptions, conversation.id),
    mutationKey: deleteChatConversationQueryKey(),
    onMutate: () => {
      queryClient.setQueryData<Conversations>(getChatConversationsQueryKey(), (oldData) => {
        if (!oldData) return oldData;
        return oldData.filter((conv) => conv.id !== conversation.id);
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getChatConversationsQueryKey(),
      });
    },
  });

  const handleDeleteItem: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      if (window.confirm(`Do you want to delete item "${conversation.title}"?`)) {
        removeItemMutation.mutate();
      }
    },
    [removeItemMutation, conversation.title],
  );

  return handleDeleteItem;
};

export default useDeleteChatConversation;
