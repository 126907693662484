import { supportedLanguages } from "../../utils/displayValues";
import useGetDefaultRequestOptions from "./useGetDefaultRequestOptions";
import { useQuery } from "@tanstack/react-query";
import { getGenerativeAIGuidance, getGenerativeAIGuidanceQueryKey } from "../../queryFunctions/getGenerativeAIGuidance";

export const useGenerativeAIGuidance = (language: supportedLanguages) => {
  const defaultRequestOptions = useGetDefaultRequestOptions();

  return useQuery<string, Error>({
    queryKey: getGenerativeAIGuidanceQueryKey(language),
    queryFn: getGenerativeAIGuidance(defaultRequestOptions, language),
  });
};
