import { MAX_CHAR_LIMIT } from "../../constants";

type CharacterLimitWarningProps = {
  text: string;
};
export const CharacterLimitWarning = ({ text }: CharacterLimitWarningProps) => {
  return (
    <div className="character-limit-warning">
      {text.length} / {MAX_CHAR_LIMIT} characters
    </div>
  );
};
