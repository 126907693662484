import throwErrorOnUnauthorized from "./throwErrorOnUnauthorized";
import { supportedLanguages } from "../utils/displayValues";

export const getGenerativeAIGuidanceQueryKey = (language: supportedLanguages) => {
  return ["GenerativeAIGuidance", language];
};

export const getGenerativeAIGuidance = (defaultRequestOptions: RequestInit, language: supportedLanguages) => {
  return async (): Promise<string> => {
    const response = await fetch(`/docs/guidance/guidance-v1-${language}.md`, defaultRequestOptions);

    throwErrorOnUnauthorized(response);

    return await response.text();
  };
};
