import { useQuery } from "@tanstack/react-query";
import { UserInfo } from "../../types";
import { getRefreshToken, getRefreshTokenQueryKey } from "../../queryFunctions/getRefreshToken";

export const useGetRefreshToken = () =>
  useQuery<UserInfo, Error>({
    queryKey: getRefreshTokenQueryKey(),
    queryFn: getRefreshToken(),
    enabled: false,
    retry: false,
  });
