import React from "react";
import ReactMarkdown from "react-markdown";

import "./GuidancePageContentStyles.css";
import { useGenerativeAIGuidance } from "../../../hooks/api/useGenerativeAIGuidance";
import { supportedLanguages } from "../../../utils/displayValues";
import { LoadingPage } from "../LoadingPage";

type GuidanceInfoContentProps = {
  language: supportedLanguages;
};

export const GuidanceInfoContent = ({ language }: GuidanceInfoContentProps) => {
  const { data, isLoading } = useGenerativeAIGuidance(language);
  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <div className="text-container">{data && <ReactMarkdown>{data}</ReactMarkdown>}</div>
    </div>
  );
};
