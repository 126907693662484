import throwErrorOnUnauthorized from "./throwErrorOnUnauthorized";
import { FETCH_USER_AGENT } from "../config";
import { PingOneResponse, UserInfo } from "../types";

export const getAccessTokenQueryKey = (code: string | undefined | null) => {
  return ["accessToken", code];
};

export const getAccessToken = (code: string | undefined | null) => {
  return async (): Promise<UserInfo> => {
    const apiResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v2/token?code=${code}&redirect_uri=${process.env.REACT_APP_BASE_URL}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "User-Agent": FETCH_USER_AGENT,
        },
        mode: "cors" as RequestMode,
      },
    );

    const response: PingOneResponse = await apiResponse.json();

    throwErrorOnUnauthorized(apiResponse);

    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiresAt: response.expires_in * 1000 + Date.now(),
    };
  };
};
