import { IconButton } from "@bolt/components";
import { Conversation } from "../types";

import "./ChatHistoryItemStyles.css";
import { memo, useCallback } from "react";
import clsx from "clsx";
import useDeleteChatConversation from "../hooks/api/useDeleteChatConversation";

type ChatHistoryItemProp = {
  conversation: Conversation;
  requestChatContentFromHistory: (chatID: string) => void;
  isActive?: boolean;
};

const ChatHistoryItem = ({ conversation, requestChatContentFromHistory, isActive = false }: ChatHistoryItemProp) => {
  const handleDeleteItem = useDeleteChatConversation(conversation);
  const handleOnClick = useCallback(() => {
    requestChatContentFromHistory(conversation.id);
  }, [conversation.id, requestChatContentFromHistory]);

  return (
    <div className={clsx(isActive && "active", "chat-history-item")} onClick={handleOnClick} title={conversation.title}>
      {conversation.title}
      <IconButton
        ariaLabel="Delete convesation"
        data-testid="delete-conversation-button"
        iconName="xmark-large"
        className="delete-conversation-button"
        size="lg"
        onClick={handleDeleteItem}
      />
    </div>
  );
};

export default memo(ChatHistoryItem);
