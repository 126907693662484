import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router";

import reportWebVitals from "./reportWebVitals";

import App from "./App";
import WrapperProvider from "./components/WrapperProvider";

import "./index.css";
import "@bolt/foundations/theme";
import "@bolt/components/styles";
import { FeatureFlagsProvider } from "./contexts/FeatureFlagsContext";
import Cookies from "js-cookie";
import { REFRESH_TOKEN_COOKIE_DEFAULT_EXPIRATION_DELAY_MS, REFRESH_TOKEN_COOKIE_NAME } from "./config/cookies";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

async function enableMocking() {
  if (process.env.REACT_APP_MOCK_API === "true") {
    const { worker } = await import("./mocks/browser");

    Cookies.set(REFRESH_TOKEN_COOKIE_NAME, "MOCK_COOKIE", {
      expires: new Date(Date.now() + REFRESH_TOKEN_COOKIE_DEFAULT_EXPIRATION_DELAY_MS),
    });
    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start();
  }
}

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <WrapperProvider>
          <FeatureFlagsProvider>
            <App />
          </FeatureFlagsProvider>
        </WrapperProvider>
      </BrowserRouter>
    </React.StrictMode>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
