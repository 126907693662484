import { Conversation, Conversations, GroupedConversations } from "../types";
import groupBy from "lodash/groupBy";
import { TIME_SPAN_GROUPS } from "./timespans.consts";

function groupByTimeSpan(conversation: Conversation) {
  const conversationLastMessageDate = new Date(conversation.last_message_timestamp);

  const today = new Date();

  for (let i = TIME_SPAN_GROUPS.length - 1; i >= 0; i--) {
    const timestampLimit = new Date();
    timestampLimit.setDate(today.getDate() - TIME_SPAN_GROUPS[i].minusDays);

    if (conversationLastMessageDate.getTime() < timestampLimit.getTime()) {
      return TIME_SPAN_GROUPS[i].label;
    }
  }

  return TIME_SPAN_GROUPS[0].label;
}

function groupConversationsByTimeSpans(conversations: Conversations): GroupedConversations {
  return groupBy(conversations, groupByTimeSpan) as GroupedConversations;
}

export default groupConversationsByTimeSpans;
