import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { AuthService } from "../../services/AuthService";
import { useSetUserInfoContext, useUserInfoContext } from "../../contexts/UserInfoContext";
import { LoadingPage } from "../pages/LoadingPage";
import { LandingPage } from "../pages/LandingPage";
import { useGetAccessToken } from "../../hooks/api/useGetAccessToken";
import { useGetRefreshToken } from "../../hooks/api/useGetRefreshToken";
import { useSearchParams } from "react-router";
import { UserInfo } from "../../types";

type AuthState = "idle" | "loginPage" | "loading";

export function Auth({ children }: PropsWithChildren) {
  const [authState, setAuthState] = useState<AuthState>("loading");
  let [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const userInfo = useUserInfoContext();
  const setUserInfo = useSetUserInfoContext();

  const { refetch: refetchAccessToken } = useGetAccessToken(code);
  const { refetch: refetchRefreshToken } = useGetRefreshToken();

  const updateRefreshToken = useCallback(
    (data: UserInfo) => {
      if (data) {
        AuthService.setRefreshTokenCookie(data.refreshToken);
        setUserInfo(data);
        setAuthState("idle");
      }
    },
    [setUserInfo],
  );

  const handleRefetchRefreshToken = useCallback(async () => {
    setAuthState("loading");
    const queryResult = await refetchRefreshToken();
    if (queryResult.data) {
      updateRefreshToken(queryResult.data);
    }

    if (queryResult.isError) {
      AuthService.unsetRefreshTokenCookie();
      setUserInfo(null);
      setAuthState("loginPage");
    }
  }, [refetchRefreshToken, setUserInfo, updateRefreshToken]);

  useEffect(() => {
    setAuthState((prevState) => {
      if (prevState === "idle" && userInfo === null) {
        return "loginPage";
      }
      return prevState;
    });
  }, [userInfo]);

  useEffect(() => {
    if (code) {
      window.history.replaceState({}, "", window.location.pathname);
      setAuthState("loading");
      refetchAccessToken().then(({ data }) => data && updateRefreshToken(data));
    } else {
      const refresh_token = AuthService.getRefreshTokenCookie();
      if (refresh_token) {
        handleRefetchRefreshToken();
      } else {
        setAuthState("loginPage");
      }
    }
  }, [code, setUserInfo, refetchAccessToken, refetchRefreshToken, updateRefreshToken, handleRefetchRefreshToken]);

  useEffect(() => {
    if (userInfo?.accessToken === "" && !!userInfo?.refreshToken) {
      setAuthState("loading");
      handleRefetchRefreshToken();
    }
  }, [userInfo?.accessToken, userInfo?.refreshToken, handleRefetchRefreshToken]);

  if (authState === "loginPage") {
    return <LandingPage />;
  }

  if (authState === "loading") {
    return <LoadingPage />;
  }

  return <>{children}</>;
}
