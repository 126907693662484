import { useQuery } from "@tanstack/react-query";
import { Conversations } from "../../types";
import getChatConversations, { getChatConversationsQueryKey } from "../../queryFunctions/getChatConversations";
import useGetDefaultRequestOptions from "./useGetDefaultRequestOptions";

export const useChatHistory = () => {
  const defaultRequestOptions = useGetDefaultRequestOptions();

  return useQuery<Conversations, Error>({
    queryKey: getChatConversationsQueryKey(),
    queryFn: getChatConversations(defaultRequestOptions),
  });
};
