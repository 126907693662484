import React, { useState } from "react";
import { GuidanceInfoContent } from "./components/pages/Info/GuidanceInfoContent";
import UserGuideInfoContent from "./components/pages/Info/UserGuideInfoContent";
import FaqInfoContent from "./components/pages/Info/FaqInfoContent";
import { InfoTemplate } from "./components/pages/Info/InfoTemplate";
import { ROUTES } from "./config/routes";
import { supportedLanguages } from "./utils/displayValues";
import { Routes, Route } from "react-router";
import { Logout } from "./components/pages/Logout";
import { Auth } from "./components/Auth";
import { TermsAndConditions } from "./components/pages/TermsAndConditions";
import { ChatUi } from "./components/pages/ChatUi/ChatUi";
import { NotifyContainer } from "./components/NotifyContainer";

function App() {
  const [language, setLanguage] = useState(supportedLanguages.en);

  return (
    <>
      <Routes>
        <Route
          path={ROUTES.USER_GUIDE}
          element={
            <InfoTemplate showLanguageSelection={true} language={language} setLanguage={setLanguage} key={1}>
              <UserGuideInfoContent language={language} />
            </InfoTemplate>
          }
        />
        <Route
          path={ROUTES.ACCEPTABLE_USE_POLICY}
          element={
            <InfoTemplate showLanguageSelection={true} language={language} setLanguage={setLanguage} key={2}>
              <GuidanceInfoContent language={language} />
            </InfoTemplate>
          }
        />
        <Route
          path={ROUTES.FAQ}
          element={
            <InfoTemplate showLanguageSelection={false} language={language} setLanguage={setLanguage} key={3}>
              <FaqInfoContent />
            </InfoTemplate>
          }
        />
        <Route path={ROUTES.LOGOUT} element={<Logout />} />
        <Route
          index
          element={
            <Auth>
              <TermsAndConditions language={language} setLanguage={setLanguage}>
                <ChatUi />
              </TermsAndConditions>
            </Auth>
          }
        />
      </Routes>
      <NotifyContainer />
    </>
  );
}

export default App;
