import useGetDefaultRequestOptions from "./useGetDefaultRequestOptions";
import { useQuery } from "@tanstack/react-query";
import { getTermsAndConditions, getTermsAndConditionsQueryKey } from "../../queryFunctions/getTermsAndConditions";
import { Terms } from "../../types/TermsTypes";
import HttpError from "../../utils/HttpError";

export const useTermsAndConditions = () => {
  const defaultRequestOptions = useGetDefaultRequestOptions();

  return useQuery<Terms, HttpError>({
    queryKey: getTermsAndConditionsQueryKey(),
    queryFn: getTermsAndConditions(defaultRequestOptions),
    retry: false,
    refetchOnWindowFocus: false,
  });
};
