import { toast } from "react-toastify";
import { Notify } from "../../components/Notify";

type Message = string;

export const notifyError = (message: Message) => {
  toast.error(Notify, {
    closeButton: false,
    icon: false,
    data: {
      message,
    },
  });
};
