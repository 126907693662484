import { Bounce, ToastContainer } from "react-toastify";

export const NotifyContainer = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick={true}
      rtl={false}
      pauseOnHover
      theme={"colored"}
      transition={Bounce}
    />
  );
};
