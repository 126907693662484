import { ChatHistoryDTO, ChatMessage } from "../types";

function chatHistoryToChatHistoryDTO(chatHistory: ChatMessage[]): ChatHistoryDTO {
  return chatHistory.map((chatHistoryItem) => ({
    role: chatHistoryItem.role,
    content: chatHistoryItem.content,
  }));
}

export default chatHistoryToChatHistoryDTO;
