import { ToastContentProps } from "react-toastify";
import "./NotifyStyles.css";
import { ErrorIcon } from "../Icons";

type NotifyProps = {
  message: string;
};

export function Notify({ data }: ToastContentProps<NotifyProps>) {
  return (
    <div className="notification-container">
      <ErrorIcon />
      <div>{data.message}</div>
    </div>
  );
}
