import { z } from "zod";
import { ChatHistoryDTO } from "./ChatHistoryDTO";

export type ChatRequest = {
  chat_history: ChatHistoryDTO;
  question: string;
  chat_id: string; // guid
  file?: File | null;
};

export enum ErrorType {
  SESSION_EXPIRED = "SESSION_EXPIRED",
  TOO_MANY_TOKENS = "TOO_MANY_TOKENS",
  OTHER = "OTHER",
}

type ChatMessageAbstract =
  | {
      content: string;
      error: false;
    }
  | {
      content: string;
      error: true;
      errorType: ErrorType;
    };

export type ChatMessageAssistant = ChatMessageAbstract & {
  role: "assistant";
  id?: string;
  creation_timestamp?: string;
  isTruncated: boolean;
};

export type ChatMessageUser = ChatMessageAbstract & {
  role: "user";
  id?: string;
  creation_timestamp?: string;
  file_name?: string;
  file_type?: string;
  file?: File | null;
};

export type ChatMessage = ChatMessageAssistant | ChatMessageUser;

export const ChatResponseSchema = z.object({
  answer: z.string(),
  question_id: z.string(),
  question_timestamp: z.string(),
  is_truncated: z.boolean(),
});

export const RephraseResponseSchema = z.object({
  rephrased_prompt: z.string(),
});

export type ChatTheme = "light" | "dark";
