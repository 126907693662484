import React, { Dispatch, PropsWithChildren, SetStateAction, useEffect } from "react";
import "./termsAndConditionsStyles.css";

import ChatGPCLogomark from "../../ChatGPCLogomark";
import { useSetUserInfoContext } from "../../../contexts/UserInfoContext";
import { useAcceptTermsAndConditions } from "../../../hooks/api/useAcceptTermsAndConditions";
import { LanguageList } from "../Info/LanguageList";

import { display, supportedLanguages } from "../../../utils/displayValues";
import { useTermsAndConditions } from "../../../hooks/api/useTermsAndConditions";
import { AuthService } from "../../../services/AuthService";
import { LoadingPage } from "../LoadingPage";
import { GuidanceInfoContent } from "../Info/GuidanceInfoContent";
import { useQueryClient } from "@tanstack/react-query";
import { getTermsAndConditionsQueryKey } from "../../../queryFunctions/getTermsAndConditions";

type TermsAndConditionsProps = {
  language: supportedLanguages;
  setLanguage: Dispatch<SetStateAction<supportedLanguages>>;
};

export const TermsAndConditions = ({ setLanguage, language, children }: PropsWithChildren<TermsAndConditionsProps>) => {
  const { data: terms, isPending: isLoadingTerms, error } = useTermsAndConditions();
  const { mutateAsync, isPending: isUpdatingTerms } = useAcceptTermsAndConditions();
  const setUserInfo = useSetUserInfoContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (error?.status === 403) {
      AuthService.unsetRefreshTokenCookie();
      setUserInfo(null);
      setTimeout(() => alert(error), 1);
    }
  }, [error, isLoadingTerms, setUserInfo]);

  const handleAccept = () => {
    mutateAsync().then((terms) => {
      queryClient.setQueryData(getTermsAndConditionsQueryKey(), {
        accepted: terms,
      });
    });
  };

  const handleDecline = () => {
    AuthService.unsetRefreshTokenCookie();
    setUserInfo(null);
  };

  if (isLoadingTerms || isUpdatingTerms) {
    return <LoadingPage />;
  }

  if (!terms?.accepted) {
    return (
      <div className="terms-wrapper">
        <div className="terms-header-bar">
          <ChatGPCLogomark className="gpc-logo-terms" />
        </div>
        <div className="terms-content">
          <LanguageList language={language} setLanguage={setLanguage} />
          <div className="terms-buttons-wrapper">
            <div className="terms-body">
              <GuidanceInfoContent language={language} />
            </div>
            <div className="red-text">{display[language].guidanceDisclaimer}</div>
            <div className="button-group">
              <button onClick={handleDecline} className="decline-button" data-testid="decline-terms">
                {display[language].decline}
              </button>
              <button data-testid="accept-terms" className="accept-button" onClick={handleAccept}>
                {display[language].accept}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
