import { Heading } from "@bolt/components";
import ChatGPCLogomark from "./ChatGPCLogomark";

import "./ChatGPCLogoStyles.css";
import clsx from "clsx";
import { memo } from "react";
import { ROUTES } from "../config/routes";
import { Link } from "react-router";

type ChatGPCLogoProps = {
  className?: string;
};

const ChatGPCLogo = (props: ChatGPCLogoProps) => {
  return (
    <Link className="gpc-logo" to={ROUTES.INDEX}>
      <ChatGPCLogomark className={clsx("logo", props.className)} />
      <Heading variant="lg" level="h2">
        ChatGPC
      </Heading>
    </Link>
  );
};

export default memo(ChatGPCLogo);
