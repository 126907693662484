import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import { PluggableList } from "react-markdown/lib/react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter/dist/cjs/light";
import CopyToClipboard from "../CopyToClipboard";

import { nightOwl, prism } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { useTheme } from "../../contexts/ThemeContext";

export const AssistantMessage = ({ content }: { content: string }) => {
  const { theme } = useTheme();
  return (
    <ReactMarkdown
      className="chat-markdown"
      // Note: taken from documentation:
      // https://github.com/remarkjs/react-markdown#use-custom-components-syntax-highlight
      remarkPlugins={[remarkMath, remarkGfm]}
      rehypePlugins={[[rehypeKatex, { strict: false, displayMode: true }]] as PluggableList}
      components={{
        code(props) {
          const { children, className, node, ...rest } = props;
          const match = /language-(\w+)/.exec(className || "");
          return match ? (
            <div className="code-container">
              <SyntaxHighlighter
                {...rest}
                showLineNumbers
                children={String(children).replace(/\n$/, "")}
                language={match[1]}
                style={theme === "dark" ? nightOwl : prism}
              />
              <CopyToClipboard className="copy-code-button" textToCopy={String(children)} />
            </div>
          ) : (
            <code {...rest} className={className}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
};
