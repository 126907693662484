import { FETCH_USER_AGENT } from "../config";
import { ChatMessage } from "../types";
import throwErrorOnUnauthorized from "./throwErrorOnUnauthorized";

export const getChatMessagesQueryKey = (chatId: string) => {
  return ["chatMessages", chatId];
};

export const getChatMessages = (accessToken: string, chatID: string) => {
  return async (): Promise<ChatMessage[]> => {
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "User-Agent": FETCH_USER_AGENT,
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/v2/chat/conversations/${chatID}/messages`,
      requestOptions,
    );

    throwErrorOnUnauthorized(response);

    return (await response.json()).messages;
  };
};
