import useGetDefaultRequestOptions from "./useGetDefaultRequestOptions";
import { useMutation } from "@tanstack/react-query";
import { postTermsAndConditions } from "../../queryFunctions/postTermsAndConditions";

export const useAcceptTermsAndConditions = () => {
  const defaultRequestOptions = useGetDefaultRequestOptions();

  return useMutation<boolean, Error>({
    mutationFn: postTermsAndConditions(defaultRequestOptions),
    retry: false,
  });
};
