import { FETCH_USER_AGENT } from "../config";
import { PingOneResponse, UserInfo } from "../types";
import { AuthService } from "../services/AuthService";
import throwErrorOnUnauthorized from "./throwErrorOnUnauthorized";

export const getRefreshTokenQueryKey = () => {
  return ["refreshToken"];
};

export const getRefreshToken = () => {
  return async (): Promise<UserInfo> => {
    const refreshToken = AuthService.getRefreshTokenCookie();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refreshToken}`,
        "User-Agent": FETCH_USER_AGENT,
      },
      mode: "cors" as RequestMode,
    };

    const apiResponse = await fetch(process.env.REACT_APP_BACKEND_URL + `/api/v2/refresh`, requestOptions);

    throwErrorOnUnauthorized(apiResponse);

    const response: PingOneResponse = await apiResponse.json();

    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiresAt: response.expires_in * 1000 + Date.now(),
    };
  };
};
