import "./FileUploadStyles.css";
import React, { useRef } from "react";
import { ClipIcon } from "../Icons";

type FileUploadProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  acceptedFiles: string[];
  disabled: boolean;
};
export const FileUpload = ({ onChange, acceptedFiles, disabled }: FileUploadProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      onChange(event);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  };
  return (
    <label className="file-input-label">
      <ClipIcon />
      <span>Attach file</span>
      <input
        disabled={disabled}
        type="file"
        onChange={onChangeHandler}
        hidden
        accept={acceptedFiles.join(",")}
        ref={inputRef}
        data-testid={"file-input"}
      />
    </label>
  );
};
