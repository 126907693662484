import { useQuery } from "@tanstack/react-query";
import { getChatMessages, getChatMessagesQueryKey } from "../../queryFunctions/getChatMessages";
import { useUserInfoContext } from "../../contexts/UserInfoContext";

export const useGetConversationMessages = (chatID: string, enabled: boolean) => {
  const userInfo = useUserInfoContext()!;
  return useQuery({
    queryKey: getChatMessagesQueryKey(chatID),
    queryFn: getChatMessages(userInfo.accessToken, chatID),
    enabled,
    retry: false,
  });
};
