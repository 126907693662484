import { memo } from "react";
import "./ChatFooterDisclosureStyles.css";

const ChatFooterDisclosure = () => (
  <p className="chat-footer-disclosure">
    ChatGPC can make mistakes or display inaccurate information. Consider double-checking important information.
  </p>
);

export default memo(ChatFooterDisclosure);
