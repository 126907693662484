import { PropsWithChildren } from "react";
import FooterLinks from "../../FooterLinks";
import ChatGPCLogo from "../../ChatGPCLogo";

import "./InfoTemplateStyles.css";
import { LanguageList } from "./LanguageList";
import { supportedLanguages } from "../../../utils/displayValues";

type InfoTemplateProps = {
  showLanguageSelection: boolean;
  language: string;
  setLanguage: (language: supportedLanguages) => void;
};

export const InfoTemplate = ({
  showLanguageSelection,
  language,
  setLanguage,
  children,
}: PropsWithChildren<InfoTemplateProps>) => {
  return (
    <div className="page-informative-container" data-theme="light">
      <header>
        <ChatGPCLogo />
      </header>
      <div className="aside-wrapper">
        {showLanguageSelection && <LanguageList language={language} setLanguage={setLanguage} />}
        <main>{children}</main>
      </div>
      <FooterLinks />
    </div>
  );
};
