import { MdWarning } from "react-icons/md";

import { display } from "../utils/displayValues";

import "./DisclosureWarningStyles.css";
import { memo } from "react";

const DisclosureWarning = () => (
  <div className="disclosure">
    <MdWarning size={22} className="warning-icon" />
    <input type="checkbox" id="disclaimer-read-more-toggle" hidden />
    <p className="read-more-wrap">
      <span>{display.en.disclosure}</span>
      <label htmlFor="disclaimer-read-more-toggle" className="read-more-trigger">
        <span className="read-more-text">Read More</span>
        <span className="read-less-text">Read Less</span>
      </label>
    </p>
  </div>
);

export default memo(DisclosureWarning);
