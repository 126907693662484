import { PropsWithChildren } from "react";
import UserInfoContextWrapper from "../contexts/UserInfoContext";
import QueryClientWrapperWithErrorHandling from "./QueryClientWrapperWithErrorHandling";
import { ThemeProvider } from "../contexts/ThemeContext";

const WrapperProvider = ({ children }: PropsWithChildren) => {
  return (
    <UserInfoContextWrapper>
      <ThemeProvider>
        <QueryClientWrapperWithErrorHandling>{children}</QueryClientWrapperWithErrorHandling>
      </ThemeProvider>
    </UserInfoContextWrapper>
  );
};

export default WrapperProvider;
