import "./FooterLinksStyles.css";
import { NavLink } from "react-router";
import { ROUTES } from "../config/routes";

export const FooterLinks = () => (
  <footer>
    <p>
      <NavLink to={ROUTES.ACCEPTABLE_USE_POLICY} rel="noreferrer">
        Use policy
      </NavLink>
      <NavLink to={ROUTES.USER_GUIDE} rel="noreferrer">
        User Guide
      </NavLink>
      <NavLink to={ROUTES.FAQ} rel="noreferrer">
        FAQ
      </NavLink>
      <a href="mailto:chatgpc_support@genpt.com" rel="noreferrer" target="_blank">
        Contact Us
      </a>
    </p>
  </footer>
);

export default FooterLinks;
