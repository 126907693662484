import throwErrorOnUnauthorized from "./throwErrorOnUnauthorized";

export const postTermsAndConditions = (defaultRequestOptions: RequestInit) => {
  return async (): Promise<boolean> => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v2/terms/accept`, {
      ...defaultRequestOptions,
      method: "POST",
    });

    throwErrorOnUnauthorized(response);
    return (await response.json()) === true;
  };
};
