import React from "react";

import "./LanguageList.css";
import { supportedLanguages } from "../../../utils/displayValues";

type LanguageListProps = {
  language: string;
  setLanguage: (language: supportedLanguages) => void;
};

export const LanguageList = ({ language, setLanguage }: LanguageListProps) => {
  return (
    <>
      <aside className="language-list" data-testid="language-list">
        <h3 className="language-heading">Language</h3>
        <button
          onClick={() => setLanguage(supportedLanguages.en)}
          className={language === supportedLanguages.en ? "active" : ""}
        >
          English
        </button>
        <button
          onClick={() => setLanguage(supportedLanguages.frCa)}
          className={language === supportedLanguages.frCa ? "active" : ""}
        >
          Français (Canada)
        </button>
        <button
          onClick={() => setLanguage(supportedLanguages.frFr)}
          className={language === supportedLanguages.frFr ? "active" : ""}
        >
          Français (France)
        </button>
        <button
          onClick={() => setLanguage(supportedLanguages.es)}
          className={language === supportedLanguages.es ? "active" : ""}
        >
          Español
        </button>
        <button
          onClick={() => setLanguage(supportedLanguages.de)}
          className={language === supportedLanguages.de ? "active" : ""}
        >
          Deutsch
        </button>
        <button
          onClick={() => setLanguage(supportedLanguages.nl)}
          className={language === supportedLanguages.nl ? "active" : ""}
        >
          Nederlands
        </button>
        <button
          onClick={() => setLanguage(supportedLanguages.pt)}
          className={language === supportedLanguages.pt ? "active" : ""}
        >
          Português
        </button>
      </aside>
    </>
  );
};
