import { useEffect } from "react";
import { AuthService } from "../../../services/AuthService";
import { LoadingPage } from "../LoadingPage";

export const Logout = () => {
  useEffect(() => {
    AuthService.unsetRefreshTokenCookie();
    window.location.assign("/");
  }, []);

  return <LoadingPage />;
};
