import throwErrorOnUnauthorized from "./throwErrorOnUnauthorized";
import { Terms } from "../types/TermsTypes";
import HttpError from "../utils/HttpError";
import { ERRORS } from "../types/Errors";

export const getTermsAndConditionsQueryKey = () => {
  return ["termsAndConditions"];
};

export const getTermsAndConditions = (defaultRequestOptions: RequestInit) => {
  return async (): Promise<Terms> => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v2/terms`, {
      ...defaultRequestOptions,
      method: "POST",
    });

    if (response.status === 403) {
      throw new HttpError(ERRORS.TRAINING_REQUIRED, response.status);
    }
    throwErrorOnUnauthorized(response);

    return await response.json();
  };
};
