import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { TextareaAutosize } from "@mui/material";
import { Button, IconButton, Switch, Tooltip } from "@bolt/components";
import { ChatGpcService } from "../../../services/ChatGpcService";
import { ChatMessage, ChatMessageUser } from "../../../types";
import "./ChatUiStyles.css";
import ChatMessageComponent from "../../ChatMessageComponent";
import MessageBot from "../../MessageBot";
import SkeletonTextBlock from "../../SkeletonTextBlock";
import ChatGPCLogo from "../../ChatGPCLogo";
import { LogOutButton } from "../../LogOutButton";

import DisclosureWarning from "../../DisclosureWarning";
import MobileSidebarToggleButton from "../../MobileSidebarToggleButton";
import ChatFooterDisclosure from "../../ChatFooterDisclosure";
import clsx from "clsx";
import { useSetUserInfoContext, useUserInfoContext } from "../../../contexts/UserInfoContext";
import { useUserQuestion } from "../../../hooks/useUserQuestion";
import ChatHistory from "../../ChatHistory";
import LoadingSection from "../../LoadingSection";
import { getChatConversationsQueryKey } from "../../../queryFunctions/getChatConversations";
import chatHistoryToChatHistoryDTO from "../../../utils/chatHistoryToChatHistoryDTO";
import BotWelcomeMessage from "../../BotWelcomeMessage";
import { FileUpload } from "../../FileUpload";
import { FilePreview } from "../../FilePreview";
import { useFeatureFlags } from "../../../contexts/FeatureFlagsContext";
import { ChatToolbar } from "../../ChatToolbar";
import { CharacterLimitWarning } from "../../CharacterLimitWarning";
import {
  CHAT_API_PATH,
  CHAT_FILE_UPLOAD_API_PATH,
  MAX_CHAR_LIMIT,
  MAX_FILE_SIZE,
  MIME_TYPE_MAP,
} from "../../../constants";
import { notifyError } from "../../../utils/notify";
import "katex/dist/katex.min.css";
import { useTheme } from "../../../contexts/ThemeContext";
import { formatFileType } from "../../../utils/formatFileType";
import { useGetConversationMessages } from "../../../hooks/api/useGetConversationMessages";

function generateNewChatID() {
  return crypto.randomUUID();
}

export const ChatUi = () => {
  const { isFileUploadEnabled } = useFeatureFlags();
  const setUserInfo = useSetUserInfoContext();
  const userInfo = useUserInfoContext()!;
  const MIN_USER_REPHRASE_LENGTH = 10;
  const [isCharacterLimitExceeded, setIsCharacterLimitExceeded] = useState(false);

  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);

  const [chatState, setChatState] = useState<"ready" | "responding" | "rephrasing">("ready");
  const { userQuestion, setUserQuestion, clearQuestionContext, rephrase, file, setFile } = useUserQuestion();
  const [chatID, setChatID] = useState<string>(generateNewChatID());

  // Note: The name for this state is not perfect. Might be refactored
  const [isNewChat, setIsNewChat] = useState<boolean>(true);
  const msgEnd = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { theme, toggleTheme } = useTheme();
  const [expandedSidebarOnMobile, setExpandedSidebarOnMobile] = useState<boolean>(false);

  const queryFetchConversationMessages = useGetConversationMessages(chatID, !isNewChat);
  const queryClient = useQueryClient();
  const displayFileUploadPreview = useMemo(
    () => isFileUploadEnabled && ["ready", "rephrasing"].includes(chatState),
    [chatState, isFileUploadEnabled],
  );

  // Note: It updates state chatHistory once data are downloaded by Query
  useEffect(() => {
    if (!!queryFetchConversationMessages.data) {
      setChatHistory(queryFetchConversationMessages.data);
    }
  }, [queryFetchConversationMessages.data]);

  useEffect(() => {
    msgEnd?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [chatHistory]);

  useEffect(() => {
    if (chatState === "ready") focusTextInput();
  }, [chatState]);

  useEffect(() => {
    setIsCharacterLimitExceeded(userQuestion.length > MAX_CHAR_LIMIT);
  }, [userQuestion]);

  const focusTextInput = () => {
    if (!textAreaRef.current) return;
    const textArea = textAreaRef.current;
    textArea.focus();
    textArea.setSelectionRange(textArea.value.length, textArea.value.length);
  };

  const handleNewChat = async () => {
    await handleAbort();

    setTimeout(() => {
      setChatID(generateNewChatID());
      setIsNewChat(true);
      setChatHistory([]);
      setFile(null);
    }, 0);
  };

  const handleAbort = useCallback(async () => {
    await ChatGpcService.abortQuestion();
    clearQuestionContext();
    setChatState("ready");
  }, [clearQuestionContext, setChatState]);

  const requestChatContentFromHistory = useCallback(
    (requestChatID: string) => {
      handleAbort();
      setIsNewChat(false);
      setChatID(requestChatID);
      setFile(null);
    },
    [handleAbort, setFile],
  );

  const toggleSidebar = useCallback(() => {
    setExpandedSidebarOnMobile((state) => !state);
  }, [setExpandedSidebarOnMobile]);

  const handleSend = async () => {
    clearQuestionContext();
    await sendMessage(userQuestion, file);

    queryClient.invalidateQueries({ queryKey: getChatConversationsQueryKey() });
    setFile(null);
  };

  const formatFileUploadData = useCallback(
    (message: string, uploadFile?: File | null) => {
      const formData = new FormData();
      if (uploadFile) {
        formData.append("upload_file", uploadFile);
      }

      formData.append(
        "data",
        JSON.stringify({
          chat_id: chatID,
          question: message,
        }),
      );

      return formData;
    },
    [chatID],
  );

  const formatChatRequestPayload = useCallback(
    (message: string) =>
      JSON.stringify({
        chat_history: chatHistoryToChatHistoryDTO(chatHistory),
        question: message,
        chat_id: chatID,
      }),
    [chatHistory, chatID],
  );

  const sendMessage = useCallback(
    async (message: string, fileUpload?: File | null) => {
      setChatState("responding");
      setChatHistory((state) => [
        ...state,
        {
          content: message,
          role: "user",
          error: false,
          file_name: fileUpload?.name,
          file_type: fileUpload?.type,
          file: fileUpload,
        },
      ]);

      const result = await ChatGpcService.askQuestion({
        userInfo,
        setUserInfo,
        chatRequest: isFileUploadEnabled
          ? formatFileUploadData(message, fileUpload)
          : formatChatRequestPayload(message),
        path: isFileUploadEnabled ? CHAT_FILE_UPLOAD_API_PATH : CHAT_API_PATH,
      });

      setChatHistory((state) => [...state, result]);
      setChatState("ready");
    },
    [userInfo, setUserInfo, isFileUploadEnabled, formatFileUploadData, formatChatRequestPayload],
  );

  const handleRephrase = async () => {
    setChatState("rephrasing");
    const rephrased = await ChatGpcService.rephraseQuestion({
      question: userQuestion,
      userInfo,
      setUserInfo,
    });
    if (rephrased) rephrase.push(rephrased);
    setChatState("ready");
  };

  const handleRegenerateResponse = useCallback(() => {
    const userQuestion =
      chatHistory
        .slice()
        .reverse()
        .find((message: ChatMessage): message is ChatMessageUser => message.role === "user") || "";

    if (userQuestion) {
      sendMessage(userQuestion.content, userQuestion.file);
    } else {
      console.error("The user question from chatHistory is empty!");
    }
  }, [sendMessage, chatHistory]);

  const onKeyDownTextarea = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (isCharacterLimitExceeded || userQuestion.length === 0) {
        e.preventDefault();
        return;
      }
      handleSend();
      e.preventDefault();
    }
  };

  const handleRedirectToRoot = useCallback(() => {
    setUserInfo(null);
  }, [setUserInfo]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    if (event.target.files[0].size > MAX_FILE_SIZE) {
      notifyError("File too big. Max file size is 8 Mb.");
      return;
    }

    setFile(event.target.files[0]);
  };

  const onRemoveFileClickHandler = () => {
    setFile(null);
  };

  return (
    <div className="theme-container" data-theme={theme}>
      <div className={clsx("chat-screen-container", expandedSidebarOnMobile && "sidebar-expanded")}>
        <div className="sidebar">
          <ChatGPCLogo className="chat-img" />
          <div className="sidebarUpper">
            <div className="sidebar-category">
              <Button
                size="lg"
                intent="primary"
                data-testid="clear-history-btn"
                onClick={handleNewChat}
                disabled={isNewChat && !chatHistory.length}
              >
                New chat
              </Button>
            </div>
          </div>
          <ChatHistory activeConversationId={chatID} requestChatContentFromHistory={requestChatContentFromHistory} />
          <div className="theme-switch-container">
            <Switch
              data-testid="dark-mode-toggle"
              onClick={toggleTheme}
              label="dark mode"
              size="md"
              checked={theme === "dark"}
            />
          </div>
        </div>
        <div className="main">
          <div className="chat-header">
            <MobileSidebarToggleButton onClick={toggleSidebar} />
            <DisclosureWarning />
            <LogOutButton />
          </div>
          {queryFetchConversationMessages.isLoading ? (
            <LoadingSection />
          ) : (
            <div className="chats">
              <BotWelcomeMessage />

              {chatHistory.map((message: ChatMessage, index: number) => (
                <ChatMessageComponent
                  key={index}
                  theme={theme}
                  message={message}
                  handleRegenerateResponse={handleRegenerateResponse}
                  handleRedirectToRoot={handleRedirectToRoot}
                  isLatest={index === chatHistory.length - 1}
                />
              ))}

              {chatState === "responding" && (
                <MessageBot>
                  <div style={{ width: "100%" }}>
                    <SkeletonTextBlock />
                  </div>
                </MessageBot>
              )}
              <div ref={msgEnd} />
            </div>
          )}
          <div
            className={`input-box-container ${isCharacterLimitExceeded ? "input-box-container-error" : ""}`}
            onClick={(e) => {
              if (e.target === e.currentTarget) focusTextInput();
            }}
          >
            <div
              className={clsx("chat-text-area", {
                "error-border": isCharacterLimitExceeded,
              })}
            >
              {displayFileUploadPreview && file && (
                <FilePreview
                  onRemoveFileClick={onRemoveFileClickHandler}
                  fileName={file.name}
                  fileType={formatFileType(file.type)}
                />
              )}
              <TextareaAutosize
                ref={textAreaRef}
                data-testid="chat-input"
                aria-label="Ask chatbot"
                draggable={false}
                disabled={chatState !== "ready"}
                placeholder="Ask me a question"
                onKeyDown={onKeyDownTextarea}
                onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setUserQuestion(e.currentTarget.value)}
                value={userQuestion}
              />
            </div>
            <ChatToolbar
              left={
                <>
                  {isFileUploadEnabled && (
                    <FileUpload
                      onChange={handleFileChange}
                      acceptedFiles={Object.keys(MIME_TYPE_MAP)}
                      disabled={!!file}
                    />
                  )}
                </>
              }
              right={
                <>
                  {isCharacterLimitExceeded && <CharacterLimitWarning text={userQuestion} />}
                  {chatState === "rephrasing" && <div className="rephrase-status">Rephrasing...</div>}
                  {chatState === "ready" && rephrase.canRevert && (
                    <Button
                      className="rephrase-undo"
                      iconLeft="clock-rotate-left"
                      onClick={rephrase.revert}
                      intent="primary"
                      variant="ghost"
                      size="md"
                    >
                      Undo rephrase
                    </Button>
                  )}
                  {chatState === "ready" && (
                    <Tooltip content="Rephrase my prompt (beta)" color="inverted">
                      <IconButton
                        ariaLabel="Rephrase my prompt (beta)"
                        onClick={handleRephrase}
                        className="rephrase-button"
                        data-testid="rephrase-button"
                        size="md"
                        iconName="sparkles"
                        variant="outline"
                        disabled={userQuestion.length <= MIN_USER_REPHRASE_LENGTH || isCharacterLimitExceeded}
                      />
                    </Tooltip>
                  )}
                  {chatState !== "ready" && (
                    <Tooltip content="Stop response" color="inverted">
                      <IconButton
                        ariaLabel="Stop response"
                        onClick={handleAbort}
                        className="abort-button"
                        data-testid="abort-button"
                        size="md"
                        iconName="xmark-large"
                        variant="outline"
                        // Note: This is commented because it doesn't work as expected at current version of BOLT package
                        // The override has been added at css file
                        // intent="destructive"
                      />
                    </Tooltip>
                  )}
                  {chatState === "ready" && (
                    <Tooltip content="Submit my prompt" color="inverted">
                      <IconButton
                        ariaLabel="Submit my prompt"
                        disabled={userQuestion === "" || isCharacterLimitExceeded}
                        className="send-button"
                        data-testid="send-button"
                        onClick={handleSend}
                        size="md"
                        iconName="chevron-right"
                        variant="outline"
                      />
                    </Tooltip>
                  )}
                </>
              }
            />
          </div>
          <ChatFooterDisclosure />
        </div>
      </div>
    </div>
  );
};
