export const ClipIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
    <path
      fill={"currentColor"}
      d="M12.5 10.5c0 1.155-.405 2.139-1.217 2.95-.81.811-1.794 1.217-2.95 1.217-1.155 0-2.138-.406-2.95-1.217-.81-.811-1.216-1.794-1.216-2.95V4.333c0-.833.291-1.541.875-2.125a2.893 2.893 0 0 1 2.125-.875c.833 0 1.541.292 2.125.875.583.584.875 1.292.875 2.125v5.834c0 .51-.178.944-.534 1.3a1.769 1.769 0 0 1-1.3.533c-.51 0-.944-.178-1.3-.533a1.769 1.769 0 0 1-.533-1.3V4h1.333v6.167a.486.486 0 0 0 .5.5.486.486 0 0 0 .5-.5V4.333a1.684 1.684 0 0 0-.491-1.183 1.582 1.582 0 0 0-1.175-.483 1.61 1.61 0 0 0-1.184.483A1.61 1.61 0 0 0 5.5 4.333V10.5a2.663 2.663 0 0 0 .817 2.008c.555.55 1.228.825 2.016.825.778 0 1.44-.275 1.984-.825.544-.55.828-1.22.85-2.008V4H12.5v6.5Z"
    />
  </svg>
);

export const PdfIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path fill="#E8E8E8" d="M17.4 0H2.6v24h18.8V4l-4-4Z" />
    <path
      fill="var(--gpc-red)"
      d="M18.208 11.869c-.289-.514-1.285-.676-1.742-.748a6.922 6.922 0 0 0-1.093-.075 13.25 13.25 0 0 0-1.152.053 7.424 7.424 0 0 1-.302-.333c-.619-.732-1.119-1.563-1.526-2.427.108-.417.195-.853.247-1.305.095-.825.128-1.766-.181-2.553-.107-.271-.392-.602-.72-.437-.378.189-.484.725-.515 1.106a3.69 3.69 0 0 0 .044.917c.053.305.137.594.23.882.086.265.18.527.284.787a13.48 13.48 0 0 1-.209.602c-.17.447-.354.871-.531 1.28l-.275.594c-.283.621-.59 1.232-.935 1.822-.804.284-1.526.613-2.126 1a4.645 4.645 0 0 0-.842.68c-.224.234-.45.537-.47.873a.55.55 0 0 0 .219.486c.213.16.496.149.744.097.811-.17 1.434-.868 1.965-1.456.365-.405.781-.919 1.216-1.54l.003-.005a18.41 18.41 0 0 1 2.42-.57 16.58 16.58 0 0 1 1.211-.156c.29.271.603.518.945.725.267.164.55.302.846.405.3.098.601.178.913.229.157.022.318.032.482.026.366-.014.893-.154.927-.6a.62.62 0 0 0-.077-.36Zm-8.777.816c-.171.266-.337.505-.493.715-.382.52-.819 1.138-1.45 1.369-.12.044-.278.089-.445.08-.148-.01-.295-.075-.288-.244a.712.712 0 0 1 .113-.31c.072-.122.162-.233.26-.335.207-.219.47-.43.773-.627a9.168 9.168 0 0 1 1.636-.814 15.51 15.51 0 0 1-.106.166Zm2.155-6.67a3.02 3.02 0 0 1-.025-.832c.014-.135.042-.267.082-.392.034-.105.107-.362.223-.395.193-.054.252.359.274.476.124.664.014 1.403-.134 2.055-.024.104-.05.206-.077.308a9.628 9.628 0 0 1-.13-.382 6.239 6.239 0 0 1-.213-.838Zm1.32 5.247c-.722.117-1.41.27-2.056.451.078-.021.434-.695.513-.835.374-.658.68-1.35.9-2.074.387.767.858 1.501 1.436 2.154.053.06.107.118.163.176a18.64 18.64 0 0 0-.955.127Zm4.888.925c-.026.143-.331.225-.474.247-.42.066-.866.014-1.268-.122a4.064 4.064 0 0 1-.796-.37 4.735 4.735 0 0 1-.708-.53c.272-.015.546-.026.822-.02.277.002.555.016.83.052.515.057 1.093.234 1.498.568.08.066.106.125.096.175ZM18.02 2H2.6V0h14.8l.62 2Z"
    />
    <path
      fill="var(--gpc-grey)"
      d="M10.04 19.09c0 .424-.124.751-.375.983-.25.23-.606.346-1.066.346H8.31v1.26H7.284v-3.798H8.6c.48 0 .84.105 1.08.315.241.21.362.507.362.893Zm-1.73.493h.188a.511.511 0 0 0 .367-.13.472.472 0 0 0 .137-.359c0-.256-.142-.384-.426-.384H8.31v.873ZM13.87 19.7c0 .634-.175 1.122-.524 1.465-.349.343-.84.514-1.471.514h-1.23v-3.798h1.315c.61 0 1.08.156 1.412.468.332.312.498.762.498 1.35Zm-1.065.036c0-.348-.07-.606-.207-.774-.138-.168-.347-.252-.627-.252h-.299v2.128h.229c.312 0 .54-.09.686-.272.145-.18.218-.457.218-.83ZM15.561 21.68h-1.01V17.88h2.244v.824h-1.234v.725h1.138v.823H15.56v1.426Z"
    />
    <path fill="var(--gpc-blue)" d="M17.4 4h4l-4-4v4Z" />
  </svg>
);

export const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16">
    <path
      fill={"var(--gpc-close-icon-background)"}
      d="M8.5 16a8 8 0 1 0 0-16.001A8 8 0 0 0 8.5 16ZM5.969 5.469a.747.747 0 0 1 1.06 0l1.468 1.468L9.966 5.47a.75.75 0 0 1 1.06 1.06l-1.47 1.468 1.469 1.469a.75.75 0 0 1-1.06 1.06l-1.468-1.47-1.469 1.469a.75.75 0 0 1-1.06-1.06l1.47-1.468-1.47-1.469a.747.747 0 0 1 0-1.06Z"
    />
  </svg>
);

export const ErrorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="var(--gpc-red-dark)"
      d="M12 17c.283 0 .52-.096.713-.288A.968.968 0 0 0 13 16a.968.968 0 0 0-.287-.713A.968.968 0 0 0 12 15a.968.968 0 0 0-.713.287A.968.968 0 0 0 11 16c0 .283.096.52.287.712.192.192.43.288.713.288Zm-1-4h2V7h-2v6Zm1 9a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20Z"
    />
  </svg>
);
