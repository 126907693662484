import { Button } from "@bolt/components";
import "./ErrorButtonStyles.css";

export const ErrorButton = ({ onClick, text }: { onClick: () => any; text: string }) => (
  <div className="chat-action-button-wrapper">
    <Button onClick={onClick} className="error-chat-button" size="lg" variant="solid" intent="primary">
      {text}
    </Button>
  </div>
);
