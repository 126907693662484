import { ChatMessage, ChatTheme, ErrorType } from "../types";
import { MdOutlineFaceUnlock } from "react-icons/md";

import "./ChatMessageComponentStyles.css";
import ChatErrorMessage from "./ChatErrorMessage";
import ChatActions from "./ChatActions";
import { memo } from "react";
import MessageBot from "./MessageBot";
import { display } from "../utils/displayValues";
import { preprocessMathMarkdown } from "../utils/preprocessMathMarkdown";
import { AssistantMessage } from "./AssistantMessage";
import { ErrorButton } from "./ErrorButton";
import { FilePreview } from "./FilePreview";
import { formatFileType } from "../utils/formatFileType";

const getErrorButton = (
  errorType: ErrorType,
  handleRegenerateResponse: () => void,
  handleRedirectToRoot: () => void,
) => {
  switch (errorType) {
    case ErrorType.SESSION_EXPIRED: {
      return <ErrorButton onClick={handleRedirectToRoot} text="Log in" />;
    }
    case ErrorType.TOO_MANY_TOKENS: {
      return null;
    }
    default: {
      return <ErrorButton onClick={handleRegenerateResponse} text="Regenerate your question" />;
    }
  }
};

type ChatMessageComponentProps = {
  message: ChatMessage;
  theme: ChatTheme;
  isLatest?: boolean;
  handleRegenerateResponse: () => void;
  handleRedirectToRoot: () => void;
};

const ChatMessageComponent = ({
  message,
  isLatest = false,
  handleRegenerateResponse,
  handleRedirectToRoot,
}: ChatMessageComponentProps) => {
  if (message.error) {
    return (
      <>
        <ChatErrorMessage errorMessage={message.content} />
        {isLatest && getErrorButton(message.errorType, handleRegenerateResponse, handleRedirectToRoot)}
      </>
    );
  }

  if (message.role === "assistant") {
    return (
      <>
        <MessageBot>
          <div>
            <AssistantMessage content={preprocessMathMarkdown(message.content)} />
            {message.isTruncated && (
              <div className="truncated-warning">
                <p>{display.en.truncationWarning}</p>
              </div>
            )}
          </div>
        </MessageBot>
        {isLatest && message.id && message.creation_timestamp && (
          <ChatActions
            textToCopy={message.content}
            questionId={message.id}
            questionTimestamp={message.creation_timestamp}
          />
        )}
      </>
    );
  }

  return (
    <div className="message user-message">
      <div>
        <MdOutlineFaceUnlock className="chat-img" size="100%" />
      </div>
      <div>
        {message.file_name && message.file_type && (
          <div className={"message-file"}>
            <FilePreview fileName={message.file_name} fileType={formatFileType(message.file_type)} />
          </div>
        )}
        <p className="user-message-content">{message.content}</p>
      </div>
    </div>
  );
};

export default memo(ChatMessageComponent);
