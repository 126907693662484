import { ROUTES } from "./routes";

const FRONTEND_BASE_URL = process.env.REACT_APP_BASE_URL;

export const PING_CONFIG = {
  /**
   * Application authorization details. For more information check "Getting Started" in README.md
   */
  authorizationUrl: process.env.REACT_APP_OAUTH_AUTHORIZATION_URL,
  logoutUrl: `${process.env.REACT_APP_OAUTH_LOGOUT_URL}?TargetResource=${encodeURIComponent(FRONTEND_BASE_URL + ROUTES.LOGOUT)}`,
  // Specifies the code or token type returned by an authorization request. Options are token, id_token, and code. Default values is "token id_token". This is a required property.
  responseType: "code",
  // Specifies the application’s UUID.
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  // Specifies the grant type of the token request. Options are authorization_code, implicit, and client_credentials
  grantType: "authorization_code",
  // Specifies the URL that specifies the return entry point of the application. This is a required property.
  redirectUri: FRONTEND_BASE_URL,
  // Specifies permissions that determine the resources that the application can access. This parameter is not required, but it is needed to specify accessible resources.
  scope: "openid profile groups email ext_attributes",
  // Specifies whether the user is prompted to login for re-authentication. The prompt parameter can be used as a way to check for existing authentication, verifying that the user is still present for the current session. For prompt=none, the user is never prompted to login to re-authenticate, which can result in an error if authentication is required. For prompt=login, if time since last login is greater than the max-age, then the current session is stashed away in the flow state and treated in the flow as if there was no previous existing session. When the flow completes, if the flow’s user is the same as the user from the stashed away session, the stashed away session is updated with the new flow data and persisted (preserving the existing session ID). If the flow’s user is not the same as the user from the stashed away session, the stashed away session is deleted (logout) and the new session is persisted.
  prompt: null,
  // Specifies the maximum amount of time allowed since the user last authenticated. If the max_age value is exceeded, the user must re-authenticate.
  maxAge: 3600,
} as const;
