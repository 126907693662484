import React, { useEffect, useRef, useState } from "react";
import { MdOutlineThumbUp, MdOutlineThumbDown } from "react-icons/md";
import "./ChatActionsStyles.css";
import clsx from "clsx";
import { FeedbackThumbsType } from "../types";
import CopyToClipboard from "./CopyToClipboard";
import { Button } from "@bolt/components";
import useFeedback from "../hooks/api/useFeedback";

type ChatActionsProps = {
  questionId: string;
  textToCopy: string;
  questionTimestamp: string;
};

const ChatActions = ({ questionId, textToCopy, questionTimestamp }: ChatActionsProps) => {
  const [userFeedback, setUserFeedback] = useState<string>("");
  const [showUserFeedbackInput, setShowUserFeedbackInput] = useState<boolean>(false);
  const [selected, setSelected] = useState<"" | FeedbackThumbsType>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const feedbackEndRef = useRef<HTMLDivElement>(null);
  const { sendFeedback } = useFeedback();

  useEffect(() => {
    if (showUserFeedbackInput) {
      feedbackEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [showUserFeedbackInput]);

  const handleSubmitFeedback = () => {
    if (selected === "") {
      console.error("This should not happened. The thumbs need to be up or down here.");
      return;
    }

    setShowUserFeedbackInput(false);

    sendFeedback({
      questionId,
      thumbs: selected,
      userFeedback,
      questionTimestamp,
    });
    setSubmitted(true);
  };

  const handleThumb = (upOrDown: FeedbackThumbsType) => {
    if (!submitted) {
      if (upOrDown === selected) {
        setSelected("");
        setShowUserFeedbackInput(false);
      } else {
        setSelected(upOrDown);
        setShowUserFeedbackInput(true);
      }
    }
  };

  return (
    <div className="feedback-container">
      <div className="feedback-btn-gr">
        <CopyToClipboard textToCopy={textToCopy} />
        <button onClick={() => handleThumb("up")} className={clsx("btn-link", selected === "up" && "up-btn-selected")}>
          <MdOutlineThumbUp size="20px" />
        </button>
        <button
          onClick={() => handleThumb("down")}
          className={clsx("btn-link", selected === "down" && "down-btn-selected")}
        >
          <MdOutlineThumbDown size="20px" />
        </button>
      </div>
      {showUserFeedbackInput && (
        <div className="feedback-form">
          <textarea
            className="feedback-input"
            data-testid="feedback-input"
            draggable={false}
            placeholder="Please provide additional feedback (optional)"
            onChange={(e: any) => setUserFeedback(e.target.value)}
            value={userFeedback}
          />
          <div>
            <Button onClick={handleSubmitFeedback} className="submit-feedback-btn" size="lg" intent="primary">
              submit
            </Button>
          </div>
        </div>
      )}
      <div ref={feedbackEndRef} />
    </div>
  );
};

export default ChatActions;
