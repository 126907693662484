import { Button } from "@bolt/components";

import { PING_CONFIG } from "../config";

import "./LogOutButtonStyles.css";

export const LogOutButton = () => (
  <Button
    variant="outline"
    size="lg"
    data-testid="logout-button"
    className="logout-button"
    onClick={() => {
      window.location.assign(PING_CONFIG.logoutUrl);
    }}
    iconLeft="right-from-bracket"
    title="Log out"
  >
    <span className="hide-on-mobile">Log out</span>
  </Button>
);
