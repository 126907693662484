import React, { createContext, PropsWithChildren, useContext } from "react";

interface FeatureFlags {
  isFileUploadEnabled: boolean;
}

const FeatureFlagsContext = createContext<FeatureFlags | undefined>(undefined);

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  const featureFlags: FeatureFlags = {
    isFileUploadEnabled: process.env.REACT_APP_FEATURE_FILE_UPLOAD === "true",
  };

  return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = (): FeatureFlags => {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    throw new Error("useFeatureFlags must be used within a FeatureFlagsProvider");
  }
  return context;
};
