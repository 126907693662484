import { Button } from "@bolt/components";
type MobileSidebarToggleButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "size" | "variant">;

const MobileSidebarToggleButton = (props: MobileSidebarToggleButtonProps) => (
  <Button
    className="mobile-sidebar-toggle-button"
    iconLeft="bars"
    size="lg"
    variant="outline"
    title="Toggle sidebar"
    {...props}
  />
);

export default MobileSidebarToggleButton;
