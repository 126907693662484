import { useQuery } from "@tanstack/react-query";
import { getAccessToken, getAccessTokenQueryKey } from "../../queryFunctions/getAccessToken";
import { UserInfo } from "../../types";

export const useGetAccessToken = (code: string | undefined | null) => {
  return useQuery<UserInfo, Error>({
    queryKey: getAccessTokenQueryKey(code),
    queryFn: getAccessToken(code),
    enabled: false,
    retry: false,
  });
};
