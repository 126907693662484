import { ReactNode } from "react";
import "./ChatToolbarStyles.css";

type ChatToolbarProps = {
  left?: ReactNode;
  right?: ReactNode;
};

export const ChatToolbar = ({ left, right }: ChatToolbarProps) => {
  return (
    <div className="chat-toolbar">
      {left && <div className="left">{left}</div>}
      {right && <div className="right">{right}</div>}
    </div>
  );
};
