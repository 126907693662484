import "./FilePreviewStyles.css";
import { CloseIcon, PdfIcon } from "../Icons";

type FileUploadProps = {
  fileName: string;
  fileType?: string;
  onRemoveFileClick?: () => void;
};

export const FilePreview = ({ fileName, fileType, onRemoveFileClick }: FileUploadProps) => {
  return (
    <div className="preview-container" data-testid={"file-upload-preview-container"}>
      <div className="file-preview">
        <PdfIcon />
        <div className="file-info">
          <div className="file-name">{fileName}</div>
          {fileType && <div className="file-type">{fileType}</div>}
        </div>
        {onRemoveFileClick && (
          <button onClick={onRemoveFileClick}>
            <CloseIcon />
          </button>
        )}
      </div>
    </div>
  );
};
